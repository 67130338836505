export const agentGeneralStepFields = [
    "first_name",
    "last_name",
    "date_of_birth",
    "gender",
    "phone_number",
    "email",
]

export const agentAddressStepFields = [
    "address_1",
    "city",
    "date_of_birth",
    "state",
    "postal_code",
    "county",
    "country"
]

export const agentLicensesStepFields = [
    "npn_number"
]

export const customerStepFields = [
    "first_name",
    "last_name",
    "date_of_birth",
    "gender",
    "phone_number",
    "applicant_member",
    "period",
    "address_1",
    "city",
    "state",
    "postal_code",
    "county",
    "name_of_employer",
    "annual_income"
]

export const membershipStepFields = [
    "number_applicants",
    "number_members",
    "number_dependents",
    "applicant_member",
    "type_dependency",
    "first_name",
    "last_name_",
    "date_of_birth",
    "phone_number",
    "gender",
    "legal_status",
    "name_of_employer",
    "position_occupation",
    "annual_income",
]

export const insuranceStepFields = [
    "npn_responsible",
    "effective_date",
    "company",
    "insurance_plan",
    "monthly_premium_amount",
]

export const extrasStepFields = [
    "i_agree"
]
